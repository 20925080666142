import store from '@/store';
import { ref, watch } from '@vue/composition-api';
import BillingStatus from '@/enums/billing-status.enum';
import Vue from 'vue';
import { isAdmin } from '@/services/jwt.service';
import Currency from '@/enums/currency.enum';
import RolesEnum from '@/enums/roles.enum';

export default function useBillingsList() {
  const billingListTable = ref([]);

  const tableColumns = [
    { text: 'ID', value: 'id', sortable: true },
    { text: 'Account Name', value: 'accountName', sortable: true },
    { text: 'Company Name', value: 'companyName', sortable: false },
    { text: 'Period', value: 'period' },
    { text: 'Amount', value: 'totalAmount' },
    { text: 'Status', value: 'status', sortable: false },
    {
      text: 'Actions',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ];

  if (isAdmin()) {
    tableColumns.splice(4, 0,
      {
        text: 'Manager',
        value: 'users',
        sortable: false
      });
    tableColumns.splice(6, 0,
      {
        text: 'Last updated',
        value: 'updatedAt',
        sortable: false
      });
  }

  const searchQuery = ref('');
  const statusFilter = ref(null);
  const status = ref(null);
  const monthFilter = ref(null);
  const companyFilter = ref(null);
  const monthFilterModal = ref(null);
  const companies = ref([]);
  const merchants = ref([]);
  const totalBillingListTable = ref(0);
  const isSendingInProcess = ref(false);
  const loading = ref(false);
  const options = ref({
    sortBy: ['createdAt'],
    sortDesc: [true],
  });
  const billingTotalLocal = ref([]);
  const selectedRows = ref([]);
  const totalAmounts = ref({
    eur: 0.0,
    usd: 0.0,
  });
  const admins = ref([]);
  const managersFilter = ref(null);

  // fetch data
  const fetchBillings = () => {
    totalAmounts.value.eur = 0.0;
    totalAmounts.value.usd = 0.0;

    store
      .dispatch('billing/fetchBillings', {
        q: searchQuery.value,
        status: statusFilter.value,
        month: monthFilter.value,
        companyId: companyFilter.value,
        managerId: managersFilter.value,
        sortBy: options.value.sortBy[0],
        sortDesc: options.value.sortDesc[0],
        page: options.value.page,
        itemsPerPage: options.value.itemsPerPage,
      })
      .then(response => {
        response.data.data.map(item => {
          switch (item.currency) {
            case Currency.EUR:
              totalAmounts.value.eur += parseFloat(item.totalAmount);
              break;
            case Currency.USD:
              totalAmounts.value.usd += parseFloat(item.totalAmountConverted);
          }
        });

        billingListTable.value = response.data.data;
        totalBillingListTable.value = response.data.totalCount;
        loading.value = false;
      })
      .catch(() => {
        Vue.notify({
          type: 'error',
          title: 'Billing',
          text: 'Cannot fetch billing',
        });
      });
  };

  // fetch data
  const fetchAdmins = () => {
    store
      .dispatch('user/fetchAdmins')
      .then(response => {
        admins.value = admins.value.concat(response.data);

      })
      .catch(() => {
        Vue.notify({
          type: 'error',
          title: 'Managers',
          text: 'Cannot fetch managers',
        });
      });
  };

  const fetchCompanies = () => {
    store
      .dispatch('company/fetchCompanies')
      .then(response => {
        companies.value = response.data;
      })
      .catch(() => {
        Vue.notify({
          type: 'error',
          title: 'Billings',
          text: 'Cannot fetch companies',
        });
      });
  };

  const fetchMerchants = () => {
    store
      .dispatch('user/fetchAll', {
        page: 1,
        role: RolesEnum.MERCHANT,
        itemsPerPage: '-1',
      })
      .then(response => {
        merchants.value = response.data.data;
      })
      .catch(() => {
        Vue.notify({
          type: 'error',
          title: 'Merchants',
          text: 'Cannot fetch merchants',
        });
      });
  };

  watch([statusFilter, companyFilter, monthFilter, options, managersFilter, searchQuery], () => {
    loading.value = true;
    selectedRows.value = [];
    fetchBillings();
  });

  if (isAdmin()) {
    fetchMerchants();
    fetchCompanies();
    fetchAdmins();
  }

  const resolveBillingStatusVariant = billingStatus => {
    if (billingStatus === BillingStatus.PAID) {
      return 'success';
    }
    return 'warning';
  };

  const resolveBillingStatusName = billingStatus => {
    if (billingStatus === BillingStatus.HOLD) {
      return BillingStatus.PENDING;
    }
    return billingStatus;
  };

  const updateStatus = () => {
    const ids = selectedRows.value.map(billing => {
      return billing.id;
    });
    store
      .dispatch('billing/bulkPatchStatus', { ids, status: status.value })
      .then(() => {
        fetchBillings();
      })
      .catch(() => {
        Vue.notify({
          type: 'error',
          title: 'Billings',
          text: 'Cannot update status',
        });
      });
  };

  const sendInvoiceToUsers = () => {
    isSendingInProcess.value = true;
    const ids = selectedRows.value.map(billing => {
      return billing.id;
    });

    store
      .dispatch('billing/sendInvoices', { ids })
      .then(() => {
        Vue.notify({
          type: 'success',
          title: 'Invoices',
          text: 'Invoices have been sent successfully',
        });
      })
      .catch(() => {
        Vue.notify({
          type: 'error',
          title: 'Invoices',
          text: 'Cannot send invoices',
        });
      })
      .finally(() => isSendingInProcess.value = false);
  };

  const deleteInvoices = () => {
    Vue.$confirm({
      message: 'Are you sure you want to delete these invoices?',
      button: {
        no: 'No',
        yes: 'Yes',
      },
      callback: confirm => {
        if (confirm) {
          const ids = selectedRows.value.map(billing => {
            return billing.id;
          });

          store
            .dispatch('billing/deleteInvoices', { ids })
            .then(() => {
              fetchBillings();
              selectedRows.value = [];
              Vue.notify({
                type: 'success',
                title: 'Invoices',
                text: 'Invoices have been deleted successfully',
              });
            })
            .catch(() => {
              Vue.notify({
                type: 'error',
                title: 'Invoices',
                text: 'Cannot delete invoices',
              });
            });
        }
      },
    });
  };

  const sendInvoice = invoice => {
    store
      .dispatch('billing/sendInvoices', { ids: [invoice.id] })
      .then(() => {
        Vue.notify({
          type: 'success',
          title: 'Invoices',
          text: 'Invoice has been sent successfully',
        });
      })
      .catch(() => {
        Vue.notify({
          type: 'error',
          title: 'Invoices',
          text: 'Cannot send invoice',
        });
      });
  };

  const deleteInvoice = invoice => {
    Vue.$confirm({
      message: 'Are you sure you want to delete this invoice?',
      button: {
        no: 'No',
        yes: 'Yes',
      },
      callback: confirm => {
        if (confirm) {
          store
            .dispatch('billing/deleteInvoice', { id: invoice.id })
            .then(() => fetchBillings())
            .catch(() => {
              Vue.notify({
                type: 'error',
                title: 'Invoice',
                text: 'Cannot delete invoice',
              });
            });
        }
      },
    });
  };

  const billingStatus = [
    { title: 'Pending', value: 'pending' },
    { title: 'Paid', value: 'paid' },
  ];

  if (isAdmin()) {
    billingStatus.push(
      { title: 'Hold', value: 'hold' },
    );
  }

  return {
    status,
    searchQuery,
    billingStatus,
    updateStatus,
    billingListTable,
    tableColumns,
    statusFilter,
    monthFilter,
    managersFilter,
    admins,
    monthFilterModal,
    companyFilter,
    merchants,
    companies,
    totalBillingListTable,
    loading,
    options,
    billingTotalLocal,
    selectedRows,
    totalAmounts,
    fetchBillings,
    resolveBillingStatusVariant,
    resolveBillingStatusName,
    sendInvoice,
    sendInvoiceToUsers,
    deleteInvoices,
    deleteInvoice,
    isSendingInProcess,
  };
}
