<template>
  <v-navigation-drawer
    :value="isAddNewInvoiceSidebarActive"
    temporary
    touchless
    id = "add-new-invoice-bar"
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 350 : '100%'"
    app
    @input="val => $emit('update:is-add-new-invoice-sidebar-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary">Add New Invoice</span>
        <v-spacer></v-spacer>
        <v-btn icon small @click="resetInvoiceData">
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
          <v-autocomplete
            v-model="invoiceData.companyId"
            :items="merchants"
            id='merchant'
            item-value="companyId"
            item-text="accountName"
            dense
            filled
            outlined
            label="Company name"
            hide-details="auto"
            class="mb-6"
          ></v-autocomplete>

          <v-menu
            ref="menu"
            v-model="isDatePickerShown"
            :return-value.sync="invoiceData.period"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                id='invoice-period'
                v-model="invoiceData.period"
                label="Period"
                :append-icon="icons.mdiCalendar"
                :rules="[validators.required]"
                outlined
                dense
                hide-details="auto"
                class="mb-6"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="invoiceDataPeriod" :max="today" type="month" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="isDatePickerShown = false"> Cancel </v-btn>
              <v-btn text color="primary" @click="$refs.menu.save(invoiceDataPeriod)"> OK </v-btn>
            </v-date-picker>
          </v-menu>

          <v-select
            v-model="invoiceData.currency"
            id="invoice-currency"
            :rules="[validators.required]"
            label="Currency"
            :items="currencies"
            outlined
            dense
            hide-details="auto"
            class="mb-6"
          >
          </v-select>

          <v-btn color="primary" class="me-3" type="submit"> Add </v-btn>
          <v-btn color="secondary" outlined type="reset" @click="resetInvoiceData"> Cancel </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { mdiCalendar, mdiClose } from '@mdi/js';
import Currency from '@/enums/currency.enum';
import { ref } from '@vue/composition-api';
import { required, requiredExtended } from '@core/utils/validation';
import dayjs from 'dayjs';
import handleError from '@/plugins/handle-error';
import store from '@/store';

export default {
  model: {
    prop: 'isAddNewInvoiceSidebarActive',
    event: 'update:is-add-new-invoice-sidebar-active',
  },
  props: {
    isAddNewInvoiceSidebarActive: {
      type: Boolean,
      required: true,
    },
    merchants: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const isDatePickerShown = ref(false);
    const blankInvoiceData = {
      companyId: '',
      period: [],
      currency: Currency.EUR,
    };

    const errorMessages = ref({ accountName: null });

    const valid = ref(false);
    const form = ref(null);
    const invoiceDataPeriod = ref(null);

    const validate = () => {
      form.value.validate();
    };

    const invoiceData = ref(JSON.parse(JSON.stringify(blankInvoiceData)));
    const resetInvoiceData = () => {
      invoiceData.value = JSON.parse(JSON.stringify(blankInvoiceData));
      emit('update:is-add-new-invoice-sidebar-active', false);
    };

    const onSubmit = () => {
      const isFormValid = form.value.validate();

      if (!isFormValid) {
        return;
      }

      store
        .dispatch('billing/addNewInvoice', invoiceData.value)
        .then(() => {
          emit('refetch-data');
          emit('update:is-add-new-invoice-sidebar-active', false);
          resetInvoiceData();
        })
        .catch(error => (errorMessages.value = handleError(error)));
    };

    return {
      today: dayjs().format('YYYY-MM-DD'),
      isDatePickerShown,
      resetInvoiceData,
      form,
      onSubmit,
      currencies: Object.values(Currency),
      invoiceData,
      errorMessages,
      invoiceDataPeriod,
      valid,
      validate,
      validators: { required, requiredExtended },
      icons: {
        mdiClose,
        mdiCalendar,
      },
    };
  },
};
</script>
