<template>
  <div id="billing-list">
    <billing-list-add-new
      v-model="isAddNewInvoiceSidebarActive"
      :merchants="merchants"
      @refetch-data="fetchBillings"
    ></billing-list-add-new>
    <!-- list filters -->
    <v-card>
      <v-card-title> Billing list </v-card-title>
      <v-row class="px-2 ma-0">
        <!-- status filter -->
        <v-col cols="12" :sm="isAdmin ? 2 : 3">
          <v-select
            class="billing-status-filter"
            v-model="statusFilter"
            placeholder="Select Status"
            :items="billingStatus"
            item-text="title"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
        </v-col>
        <v-col cols="12" :sm="isAdmin ? 2 : 3">
          <v-dialog ref="dialog" v-model="monthFilterModal" :return-value.sync="monthFilter" persistent width="290px">
            <template #activator="{ on, attrs }">
              <v-text-field
                class="billing-month-filter"
                v-model="monthFilter"
                label="Select Period"
                outlined
                dense
                clearable
                hide-details
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="monthFilter" type="month" scrollable @input="$refs.dialog.save(monthFilter)">
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col v-if="isAdmin" cols="12" sm="3">
          <v-autocomplete
            class="billing-company-name-filter"
            v-model="companyFilter"
            :items="merchants"
            item-value="companyId"
            item-text="accountName"
            dense
            filled
            outlined
            clearable
            hide-details
            label="Company name"
          ></v-autocomplete>
        </v-col>
        <v-col v-if='isAdmin' cols="12" sm="3">
          <v-autocomplete
            class="merchants-managers-filter"
            v-model="managersFilter"
            :items="admins"
            item-value="id"
            :item-text="(manager) => `${manager.firstName} ${manager.lastName}`"
            dense
            filled
            outlined
            clearable
            hide-details
            label="Select manager"
          >
          </v-autocomplete>
        </v-col>
        <v-col v-if="isAdmin" align="right" cols="12" sm="2">
          <v-btn
            color="primary"
            class="billing-create-invoice-button mb-4 me-3"
            @click.stop="isAddNewInvoiceSidebarActive = !isAddNewInvoiceSidebarActive"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Add Invoice</span>
          </v-btn>
        </v-col>
      </v-row>

      <v-divider class="mt-4 mb-4"></v-divider>

      <v-row>
      </v-row>

      <v-row>
        <v-col cols="4">
          <div class="d-flex mt-4 ml-4">
            <v-text-field
              v-model="searchQuery"
              placeholder="Search"
              outlined
              hide-details
              dense
              class="chargeback-event-search-query user-search me-3 mb-4"
            >
            </v-text-field>
          </div>
        </v-col>
        <v-col cols="8">
          <div v-if="selectedRows.length > 0" class="d-flex">
            <v-edit-dialog large persistent @save="updateStatus()">
              <v-btn color="primary" class="billing-list-update-item-status mb-4 me-4 mt-4 ms-4">
                <span>Update status</span>
              </v-btn>
              <template #input>
                <div class="mt-4 title">Update Status</div>
                <v-select
                  class="billing-select-new-status"
                  v-model="status"
                  :items="billingStatus"
                  item-text="title"
                  item-value="value"
                  placeholder="Select Status"
                  label="Status"
                ></v-select>
              </template>
            </v-edit-dialog>
            <v-btn color="primary" class="billing-send-invoice-to-users mb-4 me-4 mt-4 ms-4" @click="sendInvoiceToUsers">
              <span>Send invoices</span>
            </v-btn>
            <v-progress-circular
              indeterminate
              color="primary"
              v-if="isSendingInProcess"
            ></v-progress-circular>
            <v-btn color="primary" class="billing-delete-invoices mb-4 me-4 mt-4 ms-4" @click="deleteInvoices">
              <span>Delete invoices</span>
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <!-- actions -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-spacer></v-spacer>
      </v-card-text>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :options.sync="options"
        :headers="tableColumns"
        :items="billingListTable"
        :server-items-length="totalBillingListTable"
        :item-class="billingItemClass"
        :loading="loading"
        :footer-props="{ 'items-per-page-options': [30, 45, 50] }"
        :show-select="isAdmin"
      >
        <template #[`item.id`]="{ item }">
          <div>{{ item.id.split('-')[0] }}</div>
        </template>
        <template #[`item.status`]="{ item }">
          <v-chip
            small
            :color="resolveBillingStatusVariant(item.status)"
            :class="`${resolveBillingStatusVariant(item.status)}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            <span v-if="!isAdmin">{{ resolveBillingStatusName(item.status) }}</span>
            <span v-else>{{ item.status }}</span>
          </v-chip>
        </template>

        <template #[`item.totalAmount`]="{ item }">
          <div>{{ item.totalAmountConverted ? item.totalAmountConverted : item.totalAmount }} {{ item.currency }}</div>
        </template>

        <template #[`item.users`]="{ item }">
          <div>{{item.company.users && item.company.users.length > 0 ? item.company.users[0].manager ? item.company.users[0].manager.fullName : '' : '' }}</div>
        </template>

        <template #[`item.updatedAt`]="{ item }">
          <div> {{ item.updatedAt | moment('YYYY-MM-DD HH:mm:ss') }}</div>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{ item }">
          <v-menu bottom left>
            <template #activator="{ on, attrs }">
              <v-btn class="billing-list-item-show-more-actions" icon v-bind="attrs" v-on="on">
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item :to="{ name: 'billing-view', params: { id: item.id } }">
                <v-list-item-title class="billing-list-item-detail-invoice">
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Details</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-if="isAdmin" class="billing-list-item-send-invoice" @click="sendInvoice(item)">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiSendOutline }}
                  </v-icon>
                  <span>Send Invoice</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-if="isAdmin" class="billing-list-item-delete-invoice" @click="deleteInvoice(item)">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template #footer.page-text>
          Total amount: {{ totalAmounts.eur.toFixed(2) }} EUR / {{ totalAmounts.usd.toFixed(2) }} USD
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mdiDotsVertical, mdiPlus, mdiFileDocumentOutline, mdiSendOutline, mdiDeleteOutline } from '@mdi/js';
import BillingListAddNew from '@/views/shared/billings/billing-list/BillingListAddNew';
import Roles from '@/enums/roles.enum';
import useBillingsList from './useBillingsList';
import { ref } from '@vue/composition-api';

export default {
  components: {
    BillingListAddNew,
  },
  setup() {
    const {
      searchQuery,
      status,
      billingStatus,
      companies,
      merchants,
      billingListTable,
      tableColumns,
      statusFilter,
      monthFilter,
      monthFilterModal,
      managersFilter,
      admins,
      loading,
      options,
      billingTotalLocal,
      selectedRows,
      totalBillingListTable,
      companyFilter,
      fetchBillings,
      updateStatus,
      resolveBillingStatusVariant,
      resolveBillingStatusName,
      resolveBillingTotalIcon,
      sendInvoice,
      sendInvoiceToUsers,
      deleteInvoices,
      deleteInvoice,
      totalAmounts,
      isSendingInProcess,
    } = useBillingsList();

    const isAddNewInvoiceSidebarActive = ref(false);

    const billingItemClass = () => {
      return 'billing-item';
    };

    return {
      searchQuery,
      status,
      billingStatus,
      companies,
      merchants,
      billingListTable,
      tableColumns,
      statusFilter,
      monthFilter,
      monthFilterModal,
      companyFilter,
      totalBillingListTable,
      managersFilter,
      admins,
      loading,
      options,
      billingTotalLocal,
      isAddNewInvoiceSidebarActive,
      selectedRows,
      resolveBillingStatusVariant,
      resolveBillingStatusName,
      resolveBillingTotalIcon,
      fetchBillings,
      updateStatus,
      sendInvoice,
      sendInvoiceToUsers,
      deleteInvoices,
      deleteInvoice,
      billingItemClass,
      totalAmounts,
      icons: {
        mdiFileDocumentOutline,
        mdiPlus,
        mdiDotsVertical,
        mdiSendOutline,
        mdiDeleteOutline,
      },
      isSendingInProcess,
    };
  },
  computed: {
    isAdmin() {
      return this.$store.state.auth.profile && this.$store.state.auth.profile.role === Roles.ADMIN;
    },
  },
};
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
